import React, { useEffect } from 'react'
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
  MDBCardText,
  MDBCardLink,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MBCol,
  MDBCollapse,
  MDBInput, MDBRow
} from 'mdb-react-ui-kit';
import {Helmet} from "react-helmet"
import Header from './Header'
import Navbar from './Navbar'
import Tel from './Tel'
import Footer from './Footer'

export default function Layout({ children }) {

    return (
            <>
            <MDBContainer fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BinaryWriter - Paginas Web-Contenido 3D- Blogs Technologia</title>
                    <meta name="description" content="desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
                </Helmet>
            </MDBContainer>
            <MDBContainer fluid>
                <Navbar />
            </MDBContainer>
            <MDBContainer fluid>
                <Tel />
            </MDBContainer>
            <main className="container-fluid" style={{overflow: 'auto', width: '100%', height: '100vh'}}>{children}</main>
            <MDBContainer fluid>
              <Footer />
            </MDBContainer>
            </>
            )
}