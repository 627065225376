import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet"
import Layout from './site/Layout'
import "./Posts.css";
import Post from "./Post";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse,
    MDBInput
} from 'mdb-react-ui-kit';

const Posts = () => {
const blogPosts = [
	{
	title: "REACT.JS",
	body: `Introducción
React.js es una de las bibliotecas de JavaScript más populares para crear interfaces de usuario. Fue desarrollado por Facebook y lanzado en 2013. React.js se basa en el concepto de arquitectura basada en componentes, que permite a los desarrolladores crear componentes de interfaz de usuario reutilizables. React.js se ha convertido en la base de muchas aplicaciones web populares como Instagram, Netflix y Airbnb. En esta publicación de blog, analizaremos las bibliotecas más importantes que se utilizan con React.js y también cubriremos la importancia de Next.js.

Bibliotecas importantes de React.js
React.js tiene un vasto ecosistema de bibliotecas y herramientas que se pueden usar para mejorar el proceso de desarrollo. Estas son algunas de las bibliotecas React.js más importantes:

Redux: Redux es un contenedor de estado predecible para aplicaciones de JavaScript. Ayuda a administrar el estado de una aplicación de manera predecible, lo que facilita la depuración y las pruebas.

React Router: React Router es una biblioteca que proporciona capacidades de enrutamiento a las aplicaciones React.js. Permite a los desarrolladores definir rutas para diferentes componentes y también admite enrutamiento dinámico.

Axios: Axios es una biblioteca popular que se utiliza para realizar solicitudes HTTP desde un navegador o Node.js. Es compatible con todos los navegadores modernos y tiene funciones como la transformación automática de los datos de respuesta, la cancelación de solicitudes y el manejo de errores.

Material-UI: Material-UI es un conjunto de componentes React.js que implementan la especificación Material Design de Google. Proporciona un conjunto de componentes de interfaz de usuario preconstruidos que se pueden personalizar fácilmente para que coincidan con el estilo de cualquier aplicación.

Jest: Jest es un marco de prueba para JavaScript. Se utiliza para probar aplicaciones React.js y proporciona funciones como prueba de instantáneas, simulación y cobertura de código.

Importancia de Next.js
Next.js es un marco para crear aplicaciones React.js renderizadas del lado del servidor (SSR). Fue desarrollado por Zeit y lanzado en 2016. Estas son algunas de las razones por las que Next.js es importante:

SEO: Next.js proporciona una representación del lado del servidor lista para usar, lo que significa que los motores de búsqueda pueden rastrear e indexar fácilmente las páginas de un sitio web. Esto puede ayudar a mejorar el SEO de un sitio web.

Rendimiento: Next.js optimiza el rendimiento de un sitio web al proporcionar funciones como la división de código, que permite cargar solo el código necesario para una página específica, y la búsqueda previa, que carga los datos por adelantado para una mejor experiencia del usuario.

Sin servidor: Next.js se puede usar para crear aplicaciones sin servidor, lo que significa que los desarrolladores no necesitan preocuparse por administrar servidores o escalar la infraestructura. Esto puede ahorrar mucho tiempo y recursos a los desarrolladores.

Compatibilidad con TypeScript: Next.js tiene una excelente compatibilidad con TypeScript, que puede ayudar a detectar errores en tiempo de compilación y mejorar la calidad general del código.

Conclusión
React.js es una biblioteca poderosa para crear interfaces de usuario y tiene un vasto ecosistema de bibliotecas y herramientas que se pueden usar para mejorar el proceso de desarrollo. Next.js es un marco que se basa en React.js y proporciona características como renderizado del lado del servidor, rendimiento mejorado y arquitectura sin servidor. Al usar Next.js, los desarrolladores pueden crear aplicaciones React.js rápidas, escalables y compatibles con SEO.

Enlaces:
`,
	LinkOneName:"Redux - https://redux.js.org/",	
	LnkOne:"https://redux.js.org/",
	LinkTwoName:"React Router - https://reactrouter.com/",	
	LnkTwo:"https://reactrouter.com/", 
	LinkTreeName:"Axios - https://axios-http.com/",	
	LnkTree:"https://axios-http.com/", 
	LinkFourName:"Material-UI - https://material-ui.com/",	
	LnkFour:"https://material-ui.com/", 
	LinkFiveName:"Jest - https://jestjs.io/",	
	LnkFive:"https://jestjs.io/",
	LinkSixName:"Next.js - https://nextjs.org/",	
	LnkSix:"https://nextjs.org/",	 		 
	author: "Jorge Carretero ",
	imgUrl:
		"https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
	},
	{
	title: "Desarrollo de Aplicaciones Multiplataforma ",
	body: `Introducción:

Las aplicaciones de escritorio multiplataforma son aplicaciones de software que pueden ejecutarse en múltiples sistemas operativos. En el mundo actual, con múltiples sistemas operativos utilizados por diferentes usuarios, las aplicaciones multiplataforma se han vuelto cada vez más populares. Estas aplicaciones tienen varias ventajas sobre las aplicaciones tradicionales que están diseñadas para un sistema operativo específico. En este ensayo, analizaremos las técnicas que se utilizan para desarrollar aplicaciones de escritorio multiplataforma.


Tecnologías web:
Las tecnologías web como HTML, CSS y JavaScript se pueden utilizar para desarrollar aplicaciones multiplataforma. Estas aplicaciones se pueden ejecutar en diferentes sistemas operativos utilizando un navegador web. Los desarrolladores pueden usar marcos como Electron y NW.js para crear aplicaciones de escritorio usando tecnologías web. Estos marcos proporcionan las herramientas y las API necesarias para que los desarrolladores creen aplicaciones de escritorio que se pueden ejecutar en Windows, macOS y Linux.

Código nativo:
Los desarrolladores pueden usar código nativo para crear aplicaciones de escritorio multiplataforma. Por ejemplo, C++ se puede usar para crear aplicaciones de escritorio que se pueden ejecutar en diferentes sistemas operativos. Los marcos multiplataforma como Qt y wxWidgets brindan las herramientas y las API necesarias para que los desarrolladores creen aplicaciones de escritorio que se pueden ejecutar en diferentes sistemas operativos.

Java:
Java es otra opción popular para desarrollar aplicaciones de escritorio multiplataforma. Las aplicaciones Java pueden ejecutarse en diferentes sistemas operativos mediante el uso de una máquina virtual Java (JVM). Java proporciona un amplio conjunto de bibliotecas y API que se pueden usar para desarrollar aplicaciones de escritorio que se pueden ejecutar en Windows, macOS y Linux.

.NET:
Los desarrolladores pueden usar .NET para crear aplicaciones de escritorio multiplataforma. .NET proporciona un entorno de tiempo de ejecución común y un conjunto de bibliotecas de clases que se pueden usar para desarrollar aplicaciones de escritorio que se pueden ejecutar en diferentes sistemas operativos. Las aplicaciones .NET se pueden desarrollar con Visual Studio y se pueden ejecutar en Windows, macOS y Linux.

Conclusión:

Las aplicaciones de escritorio multiplataforma se han vuelto cada vez más populares en los últimos años. Ofrecen varias ventajas sobre las aplicaciones tradicionales que están diseñadas para un sistema operativo específico. Los desarrolladores pueden usar tecnologías web, código nativo, Java y .NET para desarrollar aplicaciones de escritorio multiplataforma. Mediante el uso de estas técnicas, los desarrolladores pueden crear aplicaciones que se pueden ejecutar en Windows, macOS y Linux. A medida que la tecnología continúa evolucionando, podemos esperar que se desarrollen más aplicaciones multiplataforma en el futuro.

Enlaces:
`,
LinkOneName:"Electron - https://www.electronjs.org/",	
LnkOne:"https://www.electronjs.org/",
LinkTwoName:"Nwjs - https://nwjs.io/",	
LnkTwo:"https://nwjs.io/", 
LinkTreeName:"Qt - https://www.qt.io/",	
LnkTree:"https://www.qt.io/", 
LinkFourName:"WxWidgets - https://www.wxwidgets.org/",	
LnkFour:"https://www.wxwidgets.org/", 
LinkFiveName:"Java - https://www.oracle.com/java/technologies/javase-jdk16-downloads.html",	
LnkFive:"https://www.oracle.com/java/technologies/javase-jdk16-downloads.html",
LinkSixName:".NET - https://dotnet.microsoft.com/download/dotnet/6.0",	
LnkSix:"https://dotnet.microsoft.com/download/dotnet/6.0",
	author: "Jorge Carretero ",
	imgUrl:
		"https://media.geeksforgeeks.org/img-practice/banner/coa-gate-2022-thumbnail.png",
	},
	{
	title: "Aplicaciones Movil",
	body: `Introducción:
	Las aplicaciones móviles se han convertido en una parte esencial de nuestras vidas. Desde el entretenimiento y las redes sociales hasta la productividad y el comercio electrónico, las aplicaciones móviles se utilizan para diversos fines. La creación de una aplicación móvil requiere diferentes tecnologías, habilidades y conocimientos. En este ensayo, discutiremos cómo crear aplicaciones móviles y las diferentes tecnologías involucradas en ello.
	
	La creación de una aplicación móvil implica varios pasos, que incluyen la ideación, el diseño, el desarrollo, las pruebas y la implementación. El primer paso es tener una idea para la aplicación. La idea debe resolver un problema particular o proporcionar una experiencia de usuario única. Una vez finalizada la idea, el siguiente paso es diseñar la interfaz de usuario y la experiencia del usuario. El diseño debe ser intuitivo, fácil de usar y visualmente atractivo.
	
	El desarrollo de una aplicación móvil implica el uso de diferentes lenguajes de programación, marcos y herramientas. Las dos plataformas principales para el desarrollo de aplicaciones móviles son Android e iOS. Las aplicaciones de Android se desarrollan con los lenguajes de programación Java o Kotlin, mientras que las aplicaciones de iOS se desarrollan con Swift u Objective-C.
	
	El proceso de desarrollo también implica elegir el marco y las herramientas adecuadas. Los marcos de desarrollo de aplicaciones móviles populares incluyen React Native, Flutter y Xamarin. Estos marcos permiten a los desarrolladores escribir código una vez e implementarlo en las plataformas Android e iOS.
	
	Una vez que se completa el proceso de desarrollo, las pruebas son esenciales para garantizar la funcionalidad, la facilidad de uso y la seguridad de la aplicación. El proceso de prueba implica varias etapas, incluidas las pruebas unitarias, las pruebas de integración y las pruebas de aceptación.
	
	Una vez que la aplicación se prueba exhaustivamente y cumple con todos los requisitos, está lista para su implementación. La implementación de una aplicación implica enviarla a la tienda de aplicaciones, donde se somete a un proceso de revisión. El proceso de revisión implica asegurarse de que la aplicación cumpla con todas las pautas y políticas de la tienda de aplicaciones.
	
	Conclusión:
	En conclusión, la creación de una aplicación móvil involucra varias tecnologías, incluidos lenguajes de programación, Frameworks y herramientas. El proceso implica la ideación, el diseño, el desarrollo, las pruebas y la implementación. Con el uso cada vez mayor de aplicaciones móviles, la demanda de desarrolladores de aplicaciones móviles calificados también está aumentando. Por lo tanto, es fundamental mantenerse al día con las últimas tecnologías y tendencias en el desarrollo de aplicaciones móviles.
	
	Enlaces:	
	`,
	LinkOneName:"Android Developers - https://developer.android.com/",	
	LnkOne:"https://developer.android.com/",
	LinkTwoName:"iOS Developers - https://developer.apple.com/",	
	LnkTwo:"https://developer.apple.com/", 
	LinkTreeName:"React Native - https://reactnative.dev/",	
	LnkTree:"https://reactnative.dev/", 
	LinkFourName:"Flutter - https://flutter.dev/",	
	LnkFour:"https://flutter.dev/", 
	LinkFiveName:"Xamarin - https://dotnet.microsoft.com/apps/xamarin",	
	LnkFive:"https://dotnet.microsoft.com/apps/xamarin",
	author: "Jorge Carretero",
	imgUrl:
		"https://media.geeksforgeeks.org/img-practice/banner/google-test-series-thumbnail.png",
	},	
];
	useEffect(() => {
		ReactGA.pageview('/blog');
	}, []);


return (
	<Layout>
        <MDBContainer className='d-flex, flex-row' fluid style={{padding: '15px', width: '100%', height: '100vh', overflow: 'auto'}}>
					<MetaTags>
						<meta name="description" content="blogs, technologia, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
						<meta name="keywords" content="blogs, technologia, desarrollo de apps, paginas web 3d, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
					</MetaTags>
				<Helmet>
					<title>BinaryWriter - Blogs Technologia</title>
					<meta name="description" content="blogs, technologia, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
				</Helmet>
                {blogPosts.map((post, index) => (
                        <Post key={index} index={index} post={post} link={post.link}  />
                        ))}
        </MDBContainer>
    </Layout>
    );
};

export default Posts;
