import { extend, Canvas, useThree, useFrame } from '@react-three/fiber'
import React, { Suspense, useRef, useMemo, useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import { useGLTF, useTexture, Center, Decal, Text3D, OrbitControls } from '@react-three/drei'
import { Debug, Physics, RigidBody } from '@react-three/rapier'
import * as THREE from "three";
import { Switch, BrowserRouter, Router, Routes, Route, withRouter, useHistory } from "react-router-dom";
import SceneModels from './components/3D/SceneModels';
//import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from './components/Home';
import CV from './components/CV';
import Posts from "./components/Posts";
import PostsVIP from './components/PostsVIP';
import Portfolio from './components/Portfolio';
import Sharonapp from './components/VR/Sharonapp';
import SharonVR from "./components/VR/SharonVR.tsx";
import {Helmet} from "react-helmet";
import './styles.css'
//import one from '../src/components/one'

import vertexShader from './vertexShader';
import fragmentShader from './fragmentShader';

const fontSettings = {
    font: '/Inter_Bold.json',
    bevelEnabled: true,
    curveSegments: 32,
    bevelThickness: 0.1,
    bevelSize: 0.04,
    bevelOffset: 0.02,
    bevelSegments: 3,
    scale: 0.5,
    height: 0.5,
    lineHeight: 0.5,
    size: 1.5,
    letterSpacing: -0.06
}

function Scene({ margin = 0.5 }) {
    const { width, height } = useThree((state) => state.viewport)
    //const ref = useRef()

    /*<RigidBody ref={ref}>*/
    return (
            <>
            <Center position={[0, 2, 0]} rotation={[0, -0.25, 0]}>
                <Text3D {...fontSettings}>
                    {`BinaryWriter`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[2, -3, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`1`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>

            <Center position={[-2, -3, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`0`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[-6, -3, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`0`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[6, -3, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`0`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            //Aqui otro nivel
            <Center position={[2, -0.8, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`0`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[-2, -0.8, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`1`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[-6, -0.8, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelEnabled={true}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`1`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <Center position={[6, -0.8, 0]}>
                <Text3D
                    curveSegments={32}
                    bevelSegments={5}
                    bevelEnabled={true}
                    bevelSize={0.04}
                    bevelThickness={0.1}
                    height={0.5}
                    lineHeight={0.5}
                    letterSpacing={-0.06}
                    size={1.5}
                    font="/Inter_Bold.json">
                    {`0`}
                    <meshNormalMaterial />
                </Text3D>
            </Center>
            <CustomGeometryParticles count={4000} />
            </>
            )
}

const CustomGeometryParticles = (props) => {
    const { count } = props;
    const radius = 13;

    // This reference gives us direct access to our points
    const points = useRef();

    // Generate our positions attributes array
    const particlesPosition = useMemo(() => {
        const positions = new Float32Array(count * 3);

        for (let i = 0; i < count; i++) {
            const distance = Math.sqrt((Math.random() - 0.5)) * radius;
            const theta = THREE.MathUtils.randFloatSpread(360);
            const phi = THREE.MathUtils.randFloatSpread(360);

            let x = distance * Math.sin(theta) * Math.cos(phi)
            let y = distance * Math.sin(theta) * Math.sin(phi);
            let z = distance * Math.cos(theta);

            positions.set([x, y, z], i * 3);
        }

        return positions;
        }, [count]);

    const uniforms = useMemo(() => ({
        uTime: {
            value: 0.0
        },
        uRadius: {
            value: radius
        }
    }), [])

    useFrame((state) => {
        const { clock } = state;

        points.current.material.uniforms.uTime.value = clock.elapsedTime;
    });

    return (
            <points ref={points}>
                <bufferGeometry>
                    <bufferAttribute
                        attach="attributes-position"
                        count={particlesPosition.length / 3}
                        array={particlesPosition}
                        itemSize={3}
                    />
                </bufferGeometry>
                <shaderMaterial
                    blending={THREE.AdditiveBlending}
                    depthWrite={false}
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={uniforms}
                />
            </points>
            );
};

export default React.memo(function App(props) {
    const history = useHistory();
    const [count, setCount] = useState(0);
    //const navigate = useNavigate();
    const hasRedirected = useRef(false);

    useEffect(() => {
      ReactGA.initialize('G-206S2F1DQX');
        const id = setInterval(() => {
            if (!hasRedirected.current) {
              const container = document.getElementById('canvas-container');
                history.push('/home');
                hasRedirected.current = true;
                while (container.firstChild) {
                  container.removeChild(container.lastChild);
                  container.style.width='0';
                  container.style.height='0';
                }
            }
            setCount((count) => count + 1)}, 4800);

            //navigate('/site', { replace: true });

        /* setTimeout(() => {
            console.log(redirectSeconds);
            setRedirectSeconds((redirectSeconds) => redirectSeconds - 1);
            }, 1000)
        */
        return () =>  clearInterval(id);
    },[]);

  return (
    <>
      <Helmet>
            <meta charSet="utf-8" />
            <title>BinaryWriter - Paginas Web - Contenido 3D- Blogs Technologia</title>
          <meta name="description" content="PaginasWeb3D, Contenido 3D, Blogs, Technologia, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
          <meta name="keywords" content="servicios tecnologia, paginas web 3d, crea tu pagina web, aplicaciones escritorio, aplicaciones movil, contenido 3D, logotipos, aplicaciones con macros, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
       </Helmet>
        <div id='canvas-container' style={{width: '100%', height: '100%'}}>
        <ThreeContent/>
        </div>
        <Switch>
            {/* <Route path='/' element={<ThreeContent/>} />        */}
            <Route path='/home' exact>
                <Home/>
            </Route>
            <Route path='/cv' exact>
                <CV/>
            </Route>
            <Route path='/posts' exact>
                <Posts/>
            </Route>
            <Route path='/postsVIPVirtualizacion' exact>
              <PostsVIP/>
            </Route>
            <Route path='/portfolio' exact>
              <Portfolio/>
            </Route>
            <Route path='/scenemodels' exact>
              <SceneModels/>
            </Route>
            <Route path='/sharonapp' exact>
              <Sharonapp/>
            </Route>
            <Route path='/sharonvr' exact>
                <SharonVR/>
            </Route>
        </Switch>
   </>
  )
});

const ThreeContent = (props) => {
    return (
      <div className='canvas'>

    <Canvas shadows orthographic camera={{ position: [0, 0, 100], zoom: 100 }}>
        <Suspense fallback={null}>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 10]} />
            <Physics colliders="trimesh">
            <Scene />
            <OrbitControls enableZoom={false} enablePan={false} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
            <Debug />
            </Physics>
        </Suspense>

    </Canvas>
   </div>
    );
}