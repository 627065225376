import React, { useState } from 'react';
import { Link, useHistory, withRouter } from "react-router-dom";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse,
    MDBInput
} from 'mdb-react-ui-kit';
import './Navbar.css'
/*import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
*/

const NavBar = (props) => {
    //const {user, error, isLoading} = useUser()
    //const {pathname} = useRouter()
    const [showNavColorSecond, setShowNavColorSecond] = useState(false);
    const history = useHistory();

    const handleHomeClick = () => {
      props.history.push('/home');
    }

    const handleCvClick = () => {
      props.history.push('/cv');
    }

    const handlePostsClick = () => {
      props.history.push('/posts');
    }

    const handlePostsVIPClick = ()  => {
      props.history.push('/postsVIPVirtualizacion');
    }

    const handlePortfolioClick = () => {
      props.history.push('/portfolio');
    }

   const handleSceneModelsClick = () => {
    props.history.push('/scenemodels');
    }

  const handleSharonAppClick = () => {
    props.history.push('/sharonapp');
  }

    const handleSharonVRClick = () => {
        props.history.push('/sharonvr');
    }


  return (

    <MDBNavbar expand='lg' dark bgColor='dark'>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img
              src='./binarywritter.png'
              height='80'
              alt='BinaryWriter'
              loading='lazy'
            />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarColor02'
            aria-controls='navbarColor02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavColorSecond(!showNavColorSecond)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorSecond} navbar id='navbarColor02'>
            <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
              <MDBNavbarItem className='active' style={{padding: '3px'}}>
                <Link to='/home'>
                  <MDBNavbarLink aria-current='page' href='/home'>
                  Home
                </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/cv'>
                      <MDBNavbarLink href='/cv'>CV curriculum</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/posts'>
                      <MDBNavbarLink href='/posts'>Blog Tecnologia</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/postsVIPVirtualizacion'>
                      <MDBNavbarLink href='/postsVIPVirtualizacion'>Blog VIP</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/portfolio'>
                      <MDBNavbarLink href='/portfolio'>Portfolio</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/scenemodels'>
                      <MDBNavbarLink href='/scenemodels'>Modelos 3d</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
              <MDBNavbarItem style={{padding: '3px'}}>
                  <Link to='/sharonapp'>
                      <MDBNavbarLink href='/sharonapp'>VR</MDBNavbarLink>
                  </Link>
              </MDBNavbarItem>
                <MDBNavbarItem style={{padding: '3px'}}>
                    <Link to='/sharonvr'>
                        <MDBNavbarLink href='/sharonvr'>Asistente VR (en desarrollo)</MDBNavbarLink>
                    </Link>
                </MDBNavbarItem>
              <MDBNavbarItem className="ms-auto">
                <img
                  src='./binarywritter.gif'
                  height='80'
                  alt='BinaryWriter'
                  loading='lazy'
                />
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
    </MDBNavbar>
    )
}

export default withRouter(NavBar);