import React from 'react'

export default function sharonapp(props) {
  return(
    <>
    <iframe allow='xr-spatial-tracking' allowFullScreen="true" src='https://sharon-pi.vercel.app/' style={{width: '100%', height: '78vh'}}>

    </iframe>
    <h4>Pongase su Headset o use una extension del navegador para ver Realidad Virtual, presione el boton de la parte inferior, si necesita ayuda envienos un mensaje con nuestro formulario de contacto</h4>
    </>
  )
}