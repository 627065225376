import React from 'react';
import { createRoot } from 'react-dom/client';
//import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './styles.css'
import App from './App'


const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

root.render(<HashRouter><App/></HashRouter>);

