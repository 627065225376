import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import {
    MDBRow,
    MDBListGroup,
    MDBListGroupItem,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardSubTitle,
    MDBCardText,
    MDBCardLink,
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse,
    MDBCardImage,
    MDBInput
} from 'mdb-react-ui-kit';
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import Layout from './site/Layout'
import { Worker } from '@react-pdf-viewer/core';
import Code from './Imagenes/cyberglasses.jpg';
import DarkThemeExample from './DarkThemeExample'
import website from './Imagenes/website.png'
//import { Document, Page } from 'react-pdf/build/entry.noworker';
//import { Document, Page, pdfjs } from 'react-pdf';
//import pdfFile from '../CV_JorgeHCarreteroGulias.pdf'

const CV = () => {
    //useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
    /*useEffect(()=> {
        const data = window.localStorage.getItem('script');
        if ( data !== null ){
            document.body.appendChild((data));
        }
    },[]);
    */
    useEffect(() => {
        ReactGA.pageview('/CV');
    }, []);

    return (
            <Layout>
            <MetaTags>
                <meta name="description" content="Curriculum Vitae, CV, - Jorge H Carretero Gulias, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
                <meta name="keywords" content="Curriculum Vitae, CV, Jorge H Carretero Gulias, desarrollo de apps, paginas web 3d, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
                <link rel="canonical" href="https://www.paginasweb3d.com/cv"/>
            </MetaTags>
            <Helmet>
                <title>CV - Jorge H Carretero Gulias</title>
                <meta name="description" content="Curriculum Vitae, CV, - Jorge H Carretero Gulias, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
            </Helmet>
            <MDBContainer fluid style={{padding: '15px', width: '100%', height: '100vh', overflow: 'auto'}}>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>BinaryWriter</MDBCardTitle>
                        <MDBCardSubTitle>CV - Curriculum</MDBCardSubTitle>
                        <MDBCardText>
                            Aqui puede ver mi Curriculum CV, tambièn puede descargarlo con el boton correspondiente. Tengo buenas bases de Programación Orientada a Objetos, Herencia, Poliformismo, etc. Conozco las siguientes tecnologías .- Lenguaje Basic, Delphi, Pascal, Lazarus, PHP, Javascript, ReactJS, Tecnologías .NET, .NET utilizando mono (es decir en Linux), NextJS, Rollup, Babel, Webpack, yarn, npm, NodeJS, SQLite, Git, Bootstrap, Diseño Responsivo, SCSS, CSS, MySQL, SQL, SQL Server, Docker, Vagrant, Flatpack, Jquery, JqueryUI, servicios REST, Android Studio, JAVA, Electron, Lenguaje C, WebServices con Soap y XML, Lenguaje C++, Kotlin, C#, Unity, Unreal Engine, Urho3D, Corona SDK, React Native, Macros en Excel o Word, Xcode, Visual Basic, Microsoft MVC (utilizando C#), Entity Framework, Python, Access, FoxPro, Mac OS, Linux, puedo programar orientado a Multiplataformas (incluso XBOX, PS4 o Nintendo Switch), Microsoft Azure, Conozco un poco de Pentesting incluyendo lo Web y el Lenguaje Rust, puedo hacer mantenimiento preventivo de Computadoras o también Ensamblarlas. En lo que respecta a un poco màs de un año en Edaiplay mejorò mi Inglès, aprendì màs de Blender, me enfoquè màs en React-Three-Fiber y en gravedad y collisiones con react-three/cannon, pues es màs sencillo de utilizar en React.js que Three.js. Cree projectos en Unreal Engine 5 por sus nuevas caracterìsticas, se ampliaron mis conocimientos sobre clases, herencia, etc pero enfocado a javascript (javascript puro). Se de AWS en la nube, aplicaciones multiplataforma en Java y JavaFX, tambièn QT.
                        </MDBCardText>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                            <DarkThemeExample fileUrl={'./Curriculum Vitae - CV - Jorge H Carretero Gulias.pdf'}/>
                        </Worker>
                    </MDBCardBody>
                </MDBCard>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardImage className = "img-fluid rounded img-thumbnail"
                                      src ={Code} alt='Que es ser un programador hoy en dia' style={{width: '100%', padding: '25px'}}/>
                        <MDBCardTitle>Que es ser un programador apasionado</MDBCardTitle>
                        <MDBCardSubTitle>Pasion por programar</MDBCardSubTitle>

                        <MDBCardText>
                            A continuacion les compartire mis pensamientos sobre que es ser un programador apasionado.

                            Ser un programador apasionado en el mundo actual significa tener un profundo amor y compromiso con el arte de la programación. No se trata solo de saber codificar o crear aplicaciones de software, sino de esforzarse constantemente por aprender, crecer y mejorar en el campo.

                            Los programadores apasionados siempre buscan nuevos desafíos y oportunidades para desarrollar sus habilidades. Están constantemente aprendiendo nuevas tecnologías, lenguajes de programación y técnicas. Aceptan proyectos paralelos y contribuciones de código abierto, buscando formas de mejorar su oficio y tener un impacto significativo en el mundo que los rodea.

                            Uno de los rasgos clave de un programador apasionado es su dedicación a la calidad. Se enorgullecen de escribir código limpio, eficiente y mantenible que está bien documentado y probado exhaustivamente. Entienden la importancia de escribir código que otros puedan entender y modificar fácilmente, y se esfuerzan por hacer que su trabajo sea lo más accesible posible.

                            Otro aspecto importante de ser un programador apasionado es la capacidad de trabajar en colaboración con otros. Los programadores apasionados entienden que el desarrollo de software es un deporte de equipo y siempre están buscando formas de colaborar con otros para lograr objetivos comunes. Están abiertos a comentarios y críticas, y buscan oportunidades para compartir sus conocimientos y experiencia con los demás.

                            Los programadores apasionados también están profundamente comprometidos con la solución de problemas del mundo real. No se conforman con simplemente escribir código por sí mismo, sino que están motivados por el deseo de tener un impacto significativo en el mundo que los rodea. Buscan oportunidades para trabajar en proyectos que tengan un impacto social o ambiental positivo, y siempre buscan formas de utilizar sus habilidades para marcar la diferencia.

                            Finalmente, los programadores apasionados entienden la importancia del equilibrio entre el trabajo y la vida. Saben que la programación puede ser un campo exigente e intenso, y se preocupan por priorizar su salud física y mental. Hacen tiempo para pasatiempos y otros intereses fuera del trabajo, y entienden que un equilibrio saludable entre el trabajo y la vida es esencial para el éxito y la satisfacción a largo plazo.

                            En conclusión, ser un programador apasionado en el mundo actual requiere un profundo amor y compromiso con el oficio de programar. Requiere un aprendizaje constante, una dedicación a la calidad, la capacidad de trabajar en colaboración, un compromiso para resolver problemas del mundo real y un enfoque en el equilibrio entre el trabajo y la vida personal. Si bien el campo de la programación puede ser desafiante, también es increíblemente gratificante para aquellos que están dispuestos a dedicar tiempo y esfuerzo para convertirse en verdaderos expertos en su campo.
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
            </Layout>
                        );
};

export default CV;
