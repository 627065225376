import React from "react";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse,
    MDBInput
} from 'mdb-react-ui-kit';

const Tel = () => {
    return (
            <MDBContainer fluid style={{height: '45px', padding: '10px'}}>
                <center><p style={{ color: 'white' }}>Telèfono de contacto +52 (55)-82-18-41-58</p></center>
            </MDBContainer>
            );
};

export default Tel;
