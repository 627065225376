import React, { useState } from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import ContactForm from '../ContactForm';
import Popup from './Popup'

//import ButtonMailto from './ButtonMailto'

const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    return (
            <MDBFooter bgColor='dark' className='text-center text-lg-start text-muted' style={{position: 'fixed', left: '0px', right: '0px', width: '100%', height: '188px', bottom: '0px', overflow: 'auto', borderRadius: '15px'}}>
                    {/*<MDBContainer className='text-center' style={{width: '100%', height: '155px', padding: '18px'}}>*/}
                        <MDBRow style={{height: '75px'}}>
                            <MDBCol className='mx-auto col-4' style={{height: '125px', padding: '18px'}}>
                                <h6 className='text-uppercase fw-bold mb-4' style={{height: '3px', width: '180px' }}>
                                    <MDBIcon icon="gem" className="me-3" />
                                    Company name
                                </h6>
                                <p style={{height: '9px', width: '155px'}}>
                                    BinaryWriter - Crea tu pagina web.
                                    &nbsp;
                                    <MDBIcon icon="phone" className="me-3" /> +52 (55)-82-18-41-58
                                    &nbsp;
                                    <a href='https://www.facebook.com/binwritter'><i className='fab fa-facebook-f' style={{color: '#3b5998'}}/></a>
                                </p>


                            </MDBCol>
                            <MDBCol className='mx-auto col-4' style={{height: '125px', padding: '18px'}}>
                                <h6 className='text-uppercase fw-bold mb-4' style={{height: '3px'}}>Contacto</h6>
                                <p style={{height: '3px'}}>
                                    <MDBIcon icon="home" className="me-2" />
                                    Ciudad de Mèxico, MEX
                                </p>
                                <div style={{position: 'absolute', width: '188px', bottom: '38px'}}>
                                    <MDBIcon icon="envelope" className="me-3" style={{width: '2px'}} />
                                    <button onClick={togglePopup} style={{width: '145px', height: '28px', paddingLeft: '5px'}}>Contactanos !!!</button>
                                    {isOpen && <Popup handleClose={togglePopup} content={<ContactForm/>}/>}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    {/*</MDBContainer>*/}


                <div className='text-center' style={{position: 'absolute', bottom: '0px', height: '28px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)', bottom: '0px'}}>
                    © 2023 Copyright:
                    <a className='text-center fw-bold' style={{marginLeft: '8px'}} href='https://paginasweb3d.com/'>
                        BinaryWriter - Crea tu pagina web
                    </a>
                </div>
            </MDBFooter>
            );
};

export default Footer;
