/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 SharonNormal.gltf --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { usePlane, useBox, Physics, useSphere } from '@react-three/cannon'

export default function SharonNormal(props) {
    const [ref] = useBox(() => ({ mass: 1, args: [1, 1,1], ...props }))
    const { nodes, materials } = useGLTF('./SharonNormal-transformed.glb')
  return (
    <group {...props} dispose={null} ref={ref}>
            <mesh geometry={nodes.Rocker_Necklace001.geometry} material={materials['Links2-2']} />
            <mesh geometry={nodes.Rocker_Thong001.geometry} material={materials['Thong-2']} />
            <mesh geometry={nodes.Lashes.geometry} material={materials['EyeMoisture-3']} />
            <mesh geometry={nodes.Lashes_1.geometry} material={materials['Eyelashes-1']} />
            <mesh geometry={nodes['geometry-2'].geometry} material={materials['Torso-1']} />
            <mesh geometry={nodes['geometry-2_1'].geometry} material={materials['Lips-1']} />
            <mesh geometry={nodes['geometry-2_2'].geometry} material={materials['Teeth-1']} />
            <mesh geometry={nodes['geometry-2_3'].geometry} material={materials['Legs-1']} />
            <mesh geometry={nodes['geometry-2_4'].geometry} material={materials['EyeSocket-1']} />
            <mesh geometry={nodes['geometry-2_5'].geometry} material={materials['Mouth-1']} />
            <mesh geometry={nodes['geometry-2_6'].geometry} material={materials['Arms-1']} />
            <mesh geometry={nodes['geometry-2_7'].geometry} material={materials['Pupils-1']} />
            <mesh geometry={nodes['geometry-2_8'].geometry} material={materials['Fingernails-1']} />
            <mesh geometry={nodes['geometry-2_9'].geometry} material={materials['Irises-1']} />
            <mesh geometry={nodes['geometry-2_10'].geometry} material={materials['Sclera-1']} />
            <mesh geometry={nodes['geometry-2_11'].geometry} material={materials['Toenails-1']} />
            <mesh geometry={nodes['geometry-2_12'].geometry} material={materials['Face-1']} />
            <mesh geometry={nodes['geometry-2_13'].geometry} material={materials['EyeMoisture-2']} />
            <mesh geometry={nodes['RRNecklace1-2'].geometry} material={materials['Studs3-2']} />
            <mesh geometry={nodes['RRNecklace1-2_1'].geometry} material={materials['Collar-5']} />
            <mesh geometry={nodes['RNRSkirt-2'].geometry} material={materials['Lace-2']} />
            <mesh geometry={nodes['RNRSkirt-2_1'].geometry} material={materials['Skirt-2']} />
            <mesh geometry={nodes['RNRSkirt-2_2'].geometry} material={materials['Studs-11']} />
            <mesh geometry={nodes['RRTop-2'].geometry} material={materials['Collar-4']} />
            <mesh geometry={nodes['RRTop-2_1'].geometry} material={materials['Buckles-2']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG.geometry} material={materials['PonyFine-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_1.geometry} material={materials['PonyFull-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_2.geometry} material={materials['FullHair-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_3.geometry} material={materials['FineHair2-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_4.geometry} material={materials['FineHair1-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_5.geometry} material={materials['Scalp-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_6.geometry} material={materials['PonyLoose-1']} />
            <mesh geometry={nodes.VertigoPonytailGN8ORG_7.geometry} material={materials['FullTop-1']} />

    </group>
  )
}

useGLTF.preload('./SharonNormal-transformed.glb')
