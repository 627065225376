import React from "react";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
  MDBCardText,
  MDBCardLink,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MBCol,
  MDBCollapse,
  MDBInput, MDBRow, MDBCol
} from 'mdb-react-ui-kit'
import {Link} from 'react-router-dom';

const Post = ({ post: { title, body,
    imgUrl, LnkOne, LinkOneName, LnkTwo, LinkTwoName, LnkThree, LinkThreeName, LnkFour, LinkFourName, LnkFive, LinkFiveName, LnkSix, LinkSixName, author }, index }) => {
    return (
      <MDBRow className='container-fluid'>
        <MDBCol>
      <MDBCard>
        <MDBCardImage className = "img-fluid rounded img-thumbnail" src ={imgUrl} alt='Blog Post de Tecnologia' fluid />
        <MDBCol md='12'>
                      <MDBCardBody>
                        <MDBCardTitle>{title}</MDBCardTitle>
                        <MDBCardSubTitle>Blogs Tecnologia</MDBCardSubTitle>
                        <MDBCardText >{body}<Link to={LnkOne}>{LinkOneName}</Link>
                            <Link to={LnkTwo}>{LinkTwoName}</Link>
                            <Link to={LnkThree}>{LinkThreeName}</Link>
                            <Link to={LnkFour}>{LinkFourName}</Link>
                            <Link to={LnkFive}>{LinkFiveName}</Link>
                            <Link to={LnkSix}>{LinkSixName}</Link>
                        </MDBCardText>
                        <div className="info">
                          <h4>Escrito por: {author}</h4>
                        </div>
                      </MDBCardBody>
                    </MDBCol>
                </MDBCard>
        </MDBCol>
      </MDBRow>
            );
};

export default Post;
