import {
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
  MDBCardText,
  MDBCardLink,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MBCol,
  MDBCollapse,
  MDBInput, MDBRow
} from 'mdb-react-ui-kit';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import Network from './Imagenes/network.png';
import Responsivo  from './Imagenes/responsivo.png';
import multiplataforma  from './Imagenes/multiplataforma.png';
import asesoria  from './Imagenes/asesoria.png';
import videojuego  from './Imagenes/videojuego.png';
import website  from './Imagenes/website.png';
import desktop  from './Imagenes/desktop.png';
import realidadvirtual  from './Imagenes/realidadvirtual.jpg';
import android  from './Imagenes/celular.jpg';
import juegoconsolap  from './Imagenes/juegoconsolap.png';
import aplicacionesmultimedia from './Imagenes/aplicacionesmultimedia.jpg'
import ui  from './Imagenes/ui.png';
import excel  from './Imagenes/excel.png';
import { Helmet } from "react-helmet";
import Layout from './site/Layout';
import React, { useEffect } from 'react';


const Home = () => {
  useEffect(() => {
    ReactGA.pageview('/home');
  }, []);
    return(
            <Layout>
              <MetaTags>
                <meta name="description" content="servicios tecnologia, paginas web 3d, aplicaciones escritorio, aplicaciones movil, contenido 3D, logotipos, aplicaciones con macros, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
                <meta name="keywords" content="servicios tecnologia, paginas web 3d, aplicaciones escritorio, aplicaciones movil, contenido 3D, logotipos, aplicaciones con macros, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
                <link rel="canonical" href="https://www.paginasweb3d.com/home"/>
              </MetaTags>
              <Helmet>
                <title>BinaryWriter - Home Page - Servicios Tecnologia</title>
                  <meta name="description" content="servicios tecnologia, paginas web 3d, aplicaciones escritorio, aplicaciones movil, contenido 3D, logotipos, aplicaciones con macros, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
              </Helmet>
              <MDBContainer fluid style={{width: '100%', height: '100vh', padding: '8px', overflow: 'auto'}}>
                <MDBRow style = {
                  {
                    paddingTop: '18px',
                    paddingRight: '15px',
                    paddingBottom: '25px',
                    textIndent: '22px',
                    paddingLeft: '28px',
                    backgroundImage: `url(${Network})`,
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '280px',
                    borderRadius: '12px'
                  }
                }>
                </MDBRow>
                <MDBCard style={{padding: '18px'}}>
                      <MDBCardBody>
                          <MDBCardTitle>BinaryWriter</MDBCardTitle>
                          <MDBCardSubTitle>Servicios</MDBCardSubTitle>
                          <MDBListGroup light numbered style={{ minWidth: '22rem' }}>
                            <MDBListGroupItem>
                              <
                                MDBCard className = 'hoverable container-fluid'
                                        style = {
                                          {
                                            width: "100%",
                                            paddingLeft: "10px",
                                            paddingRight: "12px",
                                            paddingBottom: "22px"
                                          }
                                        } >
                                <
                                  MDBCardImage className = "img-fluid rounded img-thumbnail"
                                               src = {website} alt='Sitios Web Informativos, Landing Pages con SEO, o en Tercera Dimension' />
                                  <
                                    MDBCardBody >
                                    <h1 className='display-6'>Crea tu pagina web</h1>
                                    <
                                      MDBCardTitle >Páginas Web</MDBCardTitle> <MDBCardText >
                                    Del tipo que usted necesite o guste, desde informativas como ésta que está usted leyendo en éste momento, un Blog, ó para alguna celebridad, músico o artista, enfocada a su Empresa / Negocio,
                                    marca comercial ó también un e - commerce o comercio electrónico que le deje dinero.Le gustaría con elementos en 3 D / 2 D ó talvez un WebService ? Todo ésto cubriendo diferentes presupuestos según cada necesidad específica,
                                    pero le aseguramos que somos la mejor opción en cuanto a calidad y precio.Cuéntenos sus necesidades y en base a nuestra experiencia le podemos incluso proponer varias opciones y cotizaciones.
                                    </MDBCardText> </MDBCardBody > </MDBCard>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                              <MDBCard className = 'hoverable container-fluid'
                                        style = {
                                          {
                                            width: "100%",
                                            paddingLeft: "10px",
                                            paddingRight: "12px",
                                            paddingBottom: "22px"
                                          }
                                        }>
                                <MDBCardImage className = "img-fluid rounded img-thumbnail"
                                               src={Responsivo} alt="Sitios Web Responsivos"/>

                                <MDBCardBody>
                                    <MDBCardTitle>Contenido Responsivo</MDBCardTitle> <MDBCardText>
                                    Nuestros Desarrollos Web y Aplicaciones son 100 % Responsivos, ésto es que sin importar si es una Página Web en una pantalla enorme, un celular de cualquier marca, una tablet,
                                    el contenido siempre se mostrará adecuadamente. </MDBCardText> </MDBCardBody></MDBCard>
                            </MDBListGroupItem>

                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          } >
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src = {multiplataforma} alt='Aplicaciones de Escritorio Multiplataforma o Juegos' />
                                    <
                                      MDBCardBody >
                                      <h1 className='display-6'>Aplicaciones Escritorio</h1>
                                      <MDBCardTitle > Multiplataforma </MDBCardTitle> <MDBCardText>
                                      Siempre procuramos que todo Proyecto elaborado sea Multiplataforma, es decir,
                                      que sin importar que usted utilice el Sistema Operativo Windows, Mac, Linux, su celular Android o su iPhone, nuestro trabajo también esté enfocado a éstas Plataformas.Ésto no siempre es posible y generalmente se tiene que cobrar por cada una de éstas, pues significa utilizar un nuevo lenguaje, tecnología o librería para cada uno.
                                      </MDBCardText></MDBCardBody ></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          } >
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src ={asesoria} alt='Brindamos Asesoria' />
                                    <MDBCardBody>
                                      <MDBCardTitle>Asesoría</MDBCardTitle><MDBCardText>
                                      En cuanto a Asesoría también la brindamos, necesita saber que tecnologìa le conviene, cuàl es apta para sus necesidades ?, quiere ahorrar talvez si es posible algùn dinero en èste proceso ? casi todas las Empresas generan un cargo por éste concepto.
                                    </MDBCardText></MDBCardBody></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          } >
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src = {videojuego} alt='Creamos Videojuegos y contenido Multimedia/3d' />
                                    <
                                      MDBCardBody >
                                      <MDBCardTitle>Videojuegos y contenido Multimedia</MDBCardTitle><MDBCardText>
                                      Le encantaría que le Desarrollaramos un videjuego de algun tema o género de su agrado ? Necesita algún video, animación(cinemática) o presentación ? Contáctenos para platicar los detalles mediante WhatsApp ó nuestro Formulario de Contacto.
                                      </MDBCardText></MDBCardBody></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          } >
                                  <
                                    MDBCardImage style = {
                                    {
                                      minWidth: '296px'
                                    }
                                  }
                                                 className = "img-fluid rounded img-thumbnail"
                                                 src ={ui} alt='Creamos Interfaces de Usuario' />
                                    <
                                      MDBCardBody >
                                      <MDBCardTitle>Interfaces 3 D / 2 D</MDBCardTitle><MDBCardText>
                                      También creamos Interfaces 3 D / 2 D a su gusto(siempre y cuando sea factible el diseño),
                                      los usos son múltiples como Aplicaciones de Escritorio(Windows, Mac ó Linux), Páginas Web(Responsivas incluso en 3 D), Aplicaciones Android o iOS y finalmente consolas de videojuegos.
                                      </MDBCardText></MDBCardBody></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem><
                                MDBCard className = 'hoverable container-fluid'
                                        style = {
                                          {
                                            width: "100%",
                                            paddingLeft: "10px",
                                            paddingRight: "12px",
                                            paddingBottom: "22px"
                                          }
                                        } >
                                <
                                  MDBCardImage className = "img-fluid rounded img-thumbnail"
                                               src ={android} alt='Aplicaciones Android / IOS moviles' />
                                  <
                                    MDBCardBody >
                                    <h1 className='display-6'>Aplicaciones movil</h1>
                                    <MDBCardTitle>Aplicaciones Android / IOS moviles</MDBCardTitle><MDBCardText>
                                    Empezando por una gran IDEA, algo nuevo y fresco, creamos Aplicaciones Moviles de Android y IOS, una interfaz atractiva, quiere ganar dinero con su App ?.
                                    </MDBCardText></MDBCardBody></MDBCard></MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          }>
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src ={juegoconsolap} alt='Contenido 3D, paginas web 3d, cinematicas 3D'/>
                                    <
                                      MDBCardBody >
                                      <h1 className='display-6'>Contenido 3d</h1>
                                      <h1 className='display-6'>Paginas web 3d</h1>
                                      <MDBCardTitle>Contenido 3D</MDBCardTitle><MDBCardText>
                                      Anuncios, Cinematicas en 3D, Logotipos en 3D, contenido 3D, pagina web en 3D.
                                      </MDBCardText></MDBCardBody></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          }>
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src ={realidadvirtual} alt='Aplicacaciones o Juegos en Realidad Virtual'/>
                                    <
                                      MDBCardBody >
                                      <MDBCardTitle>Realidad Virtual</MDBCardTitle><MDBCardText>
                                      La realidad virtual puede coo - existir en muchos campos de la ciencia y Tecnología, como por ejemplo en la Medicina, para Simuladores, aviación, entretenimiento por supuesto como Videojuegos, como terapia de rehabilitación en ambientes controlados y talvez muchas cosas más que todavía no descubrimos como sociedad.Siempre estamos dispuestos a escuchar sus ideas al respecto.
                                      </MDBCardText></MDBCardBody></MDBCard>
                              </MDBListGroupItem>
                              <MDBListGroupItem>
                                <
                                  MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          }>
                                  <
                                    MDBCardImage className = "img-fluid rounded img-thumbnail"
                                                 src ={desktop} alt='Aplicaciones de Escritorio Multiplataforma'/>
                                    <
                                      MDBCardBody >
                                      <MDBCardTitle>Aplicaciones de Escritorio Multiplataforma</MDBCardTitle>
                                      <MDBCardText >
                                      Necesita una aplicación para su Sistema Operativo Mac OS que realice alguna tarea específica ?
                                      Talvez lo mismo pero para Windows o Linux ? Algún punto de venta para su negocio, talvez basado en pantallas Touchscreen o Táctiles, cuéntenos sus necesidades!!!
                                    </MDBCardText>
                                    </MDBCardBody >
                                </MDBCard>
                              </MDBListGroupItem>
                            <MDBListGroupItem>
                              <
                                MDBCard className = 'hoverable container-fluid'
                                        style = {
                                          {
                                            width: "100%",
                                            paddingLeft: "10px",
                                            paddingRight: "12px",
                                            paddingBottom: "22px"
                                          }
                                        } >
                                <
                                  MDBCardImage className = "img-fluid rounded img-thumbnail"
                                               src = {aplicacionesmultimedia} alt='Aplicaciones Multimedia' />
                                <MDBCardBody><MDBCardTitle>Aplicaciones Multimedia</MDBCardTitle><MDBCardText>
                                  Ya sea Videopositivas Multimedia, Video Juegos, Videos con contenido Multimedia, podemos crear para usted Aplicaciones Multimedia para su negocio o simplemente informativas, dicen que una imagen vale mil palabras !!!.
                                </MDBCardText></MDBCardBody></MDBCard>
                            </MDBListGroupItem>
                              <MDBListGroupItem>
                                <MDBCard className = 'hoverable container-fluid'
                                          style = {
                                            {
                                              width: "100%",
                                              paddingLeft: "10px",
                                              paddingRight: "12px",
                                              paddingBottom: "22px"
                                            }
                                          }>
                                  <MDBCardImage className = "img-fluid rounded img-thumbnail" src={excel} alt='Aplicaciones Excel, Word, PowerPoint a la Medida'>

                                  </MDBCardImage>
                                    <MDBCardBody>
                                      <MDBCardTitle>Aplicaciones Excel, Word, PowerPoint a la Medida</MDBCardTitle><MDBCardText>
                                      Creamos tambièn aplicaciones Excel, Word o PowerPoint a la Medida, solo cuentenos sus necesidades !!!
                                      </MDBCardText>
                                    </MDBCardBody>
                                  </MDBCard>
                              </MDBListGroupItem>&nbsp;
                          </MDBListGroup>
                        <MDBCardText>
                          Entre otros servicios que brindamos esta la creaciòn de Paginas Web, con contenido 'normal' o en tercera dimensiòn 3D, talvez le interesarìa una mezcla ?
                        </MDBCardText>
                        <MDBCardLink href='https://threejs.org/'>Utilizamos tecnologìas como Three.js</MDBCardLink>
                        <MDBCardLink href='https://es.reactjs.org/'>O React.js</MDBCardLink>
                       </MDBCardBody>
                    </MDBCard>
              </MDBContainer>
            </Layout>
    );
};

export default Home;