import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  //const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [numerotelefono, setNumeroTelefono] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3hrzg1r",
        "template_98hltez",
        e.target,
        "cpBNDVBO62GqbPZ-C"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setName("");
    setEmail("");
    setMessage("");
    setNumeroTelefono("");
  };

  return (
    <div style={{height: '380px', width: '550px'}}>
    <form onSubmit={handleSubmit} style={{padding: '3px', height: '380px', width: '750px'}}>
      <input
        type="text"
        name="name"
        style={{ width: '180px'}}
        placeholder="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />&nbsp;&nbsp;&nbsp;
      <input
        type="email"
        name="email"
        style={{ width: '140px'}}
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />&nbsp;&nbsp;&nbsp;
      <input
        type="text"
        name="numerotelefono"
        style={{ width: '180px'}}
        placeholder="NumeroTelefono"
        value={numerotelefono}
        onChange={(e) => setNumeroTelefono(e.target.value)}
      />&nbsp;&nbsp;&nbsp;
      <textarea
        name="message"
        placeholder="Mensaje"
        style={{ width: '210px', height: '150px', marginTop: '15px'}}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button type="submit" style={{padding: '45px', color: 'red', width: '60px', height: '45px'}}><center>Send</center></button>
    </form>
    </div>
  );
};

export default ContactForm;