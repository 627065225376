import React from "react";
//import THREE as * from 'three';
import { RigidBody } from '@react-three/rapier';
import { useGLTF, useAnimations } from "@react-three/drei";
/*import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useRef, useState } from "react"*/

export default function Environment(props) {

    const environment = useGLTF('./scifiCorridor_out/scifiCorridor.gltf')


    return(
        <RigidBody type="fixed">
        <primitive object={environment.scene} position={ [ -4, 2, -5 ] } scale={ 2 } />
    </RigidBody>
    )
}