import { useSpring, animated } from "@react-spring/three"
import { useFrame, useLoader } from "@react-three/fiber"
//import { RigidBody } from '@react-three/rapier';
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useRef, useState, useEffect } from "react"
import { useControls } from 'leva'

export default function Sharon(props) {

    const sharon = useGLTF('./sharon.gltf')
    const  animations = useAnimations(sharon.animations, sharon.scene);

    const { animationName } = useControls({
        animationName: { options: animations.names }
    })

    useEffect(()=> {
        console.log('Sharon Scene : ', animations);
        const action = animations.actions[animationName]
        action
            .reset()
            .fadeIn(0.5)
            .play()

        return () => {
            action.fadeOut(0.5)
        }
    }, [ animationName ])



         return(
             <primitive
                 object={sharon.scene}
                 scale={ 0.008 }
                 position={ [0, 0, 1]}
             />
         )
}