import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import {
  MDBRow,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardText,
  MDBCardLink,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBCollapse,
  MDBCardImage,
  MDBInput
} from 'mdb-react-ui-kit';
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import Layout from './site/Layout';
import dentalorganizer  from './Imagenes/dentalorganizer.png';
import binarywriter from './Imagenes/binarywritter.png';

const Portfolio = () => {
  const scrollContainerStyle = { width: "100%", maxHeight: "400px" };
  useEffect(() => {
    ReactGA.pageview('/portfolio');
  }, []);

  return (
    <Layout>
      <MetaTags>
        <meta name="description" content="portfolio, proyectos, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
        <meta name="keywords" content="portfolio, proyectos, desarrollo de apps, paginas web 3d, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
        <link rel="canonical" href="https://www.paginasweb3d.com/portfolio"/>
      </MetaTags>
      <Helmet>
        <title>Portfolio - Proyectos</title>
        <meta name="description" content="portfolio, proyectos, desarrollo de apps, crea tu pagina web, diseño web, app movil, desarrollo de aplicaciones moviles, desarrollo de aplicaciones multiplataforma, aplicaciones para celular, multiplataforma" />
      </Helmet>
      <MDBContainer fluid style={{padding: '15px', width: '100%', height: '100vh', overflow: 'auto'}}>
        <MDBCard className = 'hoverable container-fluid'
                 style = {
                   {
                     width: "100%",
                     paddingLeft: "10px",
                     paddingRight: "12px",
                     paddingBottom: "22px"
                   }
                 } >
          <MDBCardBody>
            <MDBCardImage className = "img-fluid rounded img-thumbnail"
                          src ={dentalorganizer} alt='Aplicaciòn Multiplataforma DentalOrganizer' style={{width: '100%', padding: '25px'}}/>
            <MDBCardTitle>BinaryWriter - Aplicaciòn Multiplataforma para Hospitales o Dentistas</MDBCardTitle>
            <MDBCardSubTitle>Portfolio - Proyectos</MDBCardSubTitle>
            <MDBCardText>
              Les presento a DentalOrganizer, nuestra Aplicaciòn Multiplataforma (Windows, MacOS, Linux, Web) que administra su Hospital o Clìnica Dental, cuenta con un completo Historial Mèdico, el Dentista puede consultar posibles medicamentos para sus pacientes, ver videos educacionales, posibles Mètodos de Tratamiento, llenar ràpidamente el Historial Mèdico, imprimir informaciòn del Paciente, llevar un registro de Ortodentogramas y pagos, entre otras cosas màs. Utilice el pequeño formulario localizado en la parte baja del sitio para solicitar una compra de esta Applicaciòn.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
        <MDBCard className = 'hoverable container-fluid'
                 style = {
                   {
                     width: "100%",
                     paddingLeft: "10px",
                     paddingRight: "12px",
                     paddingBottom: "22px"
                   }
                 } >
          <MDBCardBody>
            <MDBCardImage className = "img-fluid rounded img-thumbnail"
                          src ={binarywriter} alt='Demo Multimedia de BinaryWriter' style={{width: '100%', padding: '25px'}}/>
            <MDBCardTitle>BinaryWriter - Demo Multimedia</MDBCardTitle>
            <MDBCardSubTitle>Portfolio - Proyectos</MDBCardSubTitle>
            <MDBCardText>
              Descargue el Demo Multimedia de BinaryWriter desde AQUI, gratis !!! La descarga està disponible para Windows, MacOS y Linux.
              <MDBCardLink href='https://www.mediafire.com/file/skdqpt2g05f6nzf/BinaryWriter-1.0-linux.tar.bz2/file'>Demo Multimedia de BinaryWriter para Linux descarga !!!</MDBCardLink>
              <MDBCardLink href='https://www.mediafire.com/file/7adkal2xfy3ogbe/BinaryWriter-1.0-mac.zip/file'>Demo Multimedia de BinaryWriter para MacOS descarga !!!</MDBCardLink>
              <MDBCardLink href='https://www.mediafire.com/file/ia0nyhkt9vykq27/BinaryWriter-1.0-pc.zip/file'>Demo Multimedia de BinaryWriter para Pc descarga !!!</MDBCardLink>
              <MDBCardLink href='https://www.mediafire.com/file/p2obcnjoxvau0yz/BinaryWriter-1.0-win.zip/file'>Demo Multimedia de BinaryWriter para Windows descarga !!!</MDBCardLink>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default Portfolio;
