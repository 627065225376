import React, { useRef, Suspense } from 'react';
// import { useControls } from 'leva'
import { extend, Canvas, PointLight, Camera } from '@react-three/fiber';
import Sharon from './Sharon'
import Environment from '../Environment'
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
  MDBCardText,
  MDBCardLink,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MBCol,
  MDBCollapse,
  MDBInput, MDBRow
} from 'mdb-react-ui-kit';
import { Stats, OrbitControls, useGLTF } from '@react-three/drei'
// import { useSpring, a } from '@react-spring/three';

//import {  Physics, useSphere, useBox, usePlane, Debug } from '@react-three/cannon';
import { Physics, RigidBody } from '@react-three/rapier'
import * as THREE from 'three';
import MetaTags from 'react-meta-tags';

function Plane(props) {
  // const [ref] = usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], ...props }))
  return (
    <>
    <MetaTags>
      <link rel="canonical" href="https://www.paginasweb3d.com/scenemodels"/>
    </MetaTags>
    <RigidBody type="fixed">
      <mesh receiveShadow position-y={-1.25}>
        <boxGeometry args={[10, 0.5, 10]}/>
        <meshStandardMaterial color="greenyellow" />
      </mesh>
    </RigidBody>
    </>
  )
}


const SceneModels = () => {
  /*const gravity = useControls('Gravity', {
    x: { value: 0, min: -10, max: 10, step: 0.1 },
    y: { value: -9.8, min: -10, max: 10, step: 0.1 },
    z: { value: 0, min: -10, max: 10, step: 0.1 }
  })*/
  return (
    <MDBContainer fluid style={{height: '100%', width: '100%', overflowY: 'scroll', overflow: 'scroll'}}>
      {/*<Canvas orthographic camera={{ fov: 45, zoom: 450, near: 0.01, far: 100, position: [0, 0.5, 3]}}>*/}
      <Canvas camera={{ fov: 45, near: 0.01, far: 100, position: [0, 0, 4]}} style={{width: '100%', height: '150%'}}>
        <Physics>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          {/*<OrbitControls />*/}
          {/*<Suspense fallback={null}>*/}


          <Sharon position={[0,1,0]}/>
          <Environment />
          <Plane />
          {/*</Suspense>*/}
          {/*<Box position={[1.2, 0, 0]} />*/}
        </Physics>
      </Canvas>
    </MDBContainer>
  );
};

export default SceneModels;